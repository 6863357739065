import { useArticle, useAuth, useSite } from '@on3/ui-lib';
import Script from 'next/script';
import { useEffect } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    raptivetarget: any;
  }
}

export const Raptive = () => {
  const { article } = useArticle();
  const { user } = useAuth();
  const { pageType, currentSite } = useSite();

  useEffect(() => {
    if (typeof window !== 'undefined' && !user?.st?.includes('premium')) {
      const tags = article?.tags?.map((tag) => tag.slug) || [];

      const shouldAddWomensSportsTag =
        (currentSite?.key === 400 || currentSite?.key === 401) &&
        !tags.includes('womens-sports');

      if (shouldAddWomensSportsTag) {
        tags.push('womens-sports');
      }

      window.raptivetarget = {
        pageType,
        userStatus: user?.a ? user?.st : 'guest',
        siteKey: currentSite.key,
        contentId: article?.key,
        authorId: article?.author?.key,
        categoryKey: article?.primaryCategory,
        activeSubscriber: user?.has ?? false,
        tags,
      };
    }
  }, [pageType, user, currentSite, article]);

  if (user?.st?.includes('premium')) {
    return null;
  }

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            if (!window.__raptiveEmailDetect) {
              window.__raptiveEmailDetect = true;
              !function(){"use strict";function e(e){const t=e.match(/((?=([a-z0-9._!#$%+^&*()[\\]<>-]+))\\2@[a-z0-9._-]+\\.[a-z0-9._-]+)/gi);return t?t[0]:""}function t(t){return e(a(t.toLowerCase()))}function a(e){return e.replace(/\\s/g,"")}async function n(e){const t={sha256Hash:"",sha1Hash:""};if(!("msCrypto"in window)&&"https:"===location.protocol&&"crypto"in window&&"TextEncoder"in window){const a=(new TextEncoder).encode(e),[n,c]=await Promise.all([s("SHA-256",a),s("SHA-1",a)]);t.sha256Hash=n,t.sha1Hash=c}return t}async function s(e,t){const a=await crypto.subtle.digest(e,t);return Array.from(new Uint8Array(a)).map(e=>("00"+e.toString(16)).slice(-2)).join("")}function c(e){let t=!0;return Object.keys(e).forEach(a=>{0===e[a].length&&(t=!1)}),t}function i(e,t,a){e.splice(t,1);const n="?"+e.join("&")+a.hash;history.replaceState(null,"",n)}var o={checkEmail:e,validateEmail:t,trimInput:a,hashEmail:n,hasHashes:c,removeEmailAndReplaceHistory:i,detectEmails:async function(){const e=new URL(window.location.href),a=Array.from(e.searchParams.entries()).map(e=>\`\${e[0]}=\${e[1]}\`);let s,o;const r=["adt_eih","sh_kit"];if(a.forEach((e,t)=>{const a=decodeURIComponent(e),[n,c]=a.split("=");if("adt_ei"===n&&(s={value:c,index:t,emsrc:"url"}),r.includes(n)){o={value:c,index:t,emsrc:"sh_kit"===n?"urlhck":"urlh"}}}),s)t(s.value)&&n(s.value).then(e=>{if(c(e)){const t={value:e,created:Date.now()};localStorage.setItem("adt_ei",JSON.stringify(t)),localStorage.setItem("adt_emsrc",s.emsrc)}});else if(o){const e={value:{sha256Hash:o.value,sha1Hash:""},created:Date.now()};localStorage.setItem("adt_ei",JSON.stringify(e)),localStorage.setItem("adt_emsrc",o.emsrc)}s&&i(a,s.index,e),o&&i(a,o.index,e)},cb:"adthrive"};const{detectEmails:r,cb:l}=o;r()}();
            }
            `,
        }}
        strategy="beforeInteractive"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          if (!window.adthrive) {
          (function(w, d) {
            w.adthrive = w.adthrive || {};
            w.adthrive.cmd = w.adthrive.cmd || [];
            w.adthrive.plugin = 'adthrive-ads-manual';
            w.adthrive.host = 'ads.adthrive.com';
            var s = d.createElement('script');
            s.async = true;
            s.referrerpolicy = 'no-referrer-when-downgrade';
            s.src = 'https://' + w.adthrive.host + '/sites/67b51186e5d4c01746e72aa0/ads.min.js?referrer=' + w.encodeURIComponent(w.location.href) + '&cb=' + (Math.floor(Math.random() * 100) + 1);
            var n = d.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(s, n);
          })(window, document);
          }
          `,
        }}
        data-cfasync="false"
        data-no-optimize="1"
        strategy="afterInteractive"
      />
    </>
  );
};
