import { Facebook, Instagram, Tiktok, X, Youtube } from './Svg';

export type SocialIconType =
  | 'facebook'
  | 'instagram'
  | 'x'
  | 'twitter'
  | 'tiktok'
  | 'youtube';

interface SocialIconProps {
  type?: SocialIconType;
  className?: string;
  fill?: string;
}

export const SocialIcon = ({ type, className, fill }: SocialIconProps) => {
  const icons = {
    facebook: Facebook,
    instagram: Instagram,
    x: X,
    twitter: X,
    tiktok: Tiktok,
    youtube: Youtube,
  };

  const Icon = icons[type?.toLowerCase() as SocialIconType];

  if (!Icon) return null;

  return <Icon className={className} fill={fill} />;
};
