import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import clsx from 'clsx';

import styles from './Billboard.module.scss';

interface IAdProps {
  id: string;
}

export const Billboard = ({ id }: IAdProps) => {
  const { user } = useAuth();

  if (user?.st?.includes('premium')) {
    return null;
  }

  return (
    <div className={clsx(styles.ad, 'billboardAd')} data-ui="ad" id={id} />
  );
};
